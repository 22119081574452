import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        flowchart: imageSharp(fluid: {originalName: {eq: "Flowchart.jpg"}}) {
          fluid(maxWidth: 1920, maxHeight: 975, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }`}
    render={({ flowchart }) => (
      <Img imgStyle={{ objectFit: 'contain'}}
        objectFit='contain'
        fluid={flowchart.fluid}
        alt={i18n('Web-based platform to enable connected car services')}/>
    )}/>
);