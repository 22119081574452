import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.sass';

export default ({
  children,
  className = '',
  disabled = false,
  onClick = () => {}
}) => (
  <div onClick={disabled ? () => {} : onClick} onKeyDown={disabled ? () => {} : onClick} role={'button'} tabIndex={0}
    className={clsx(
      styles.root,
      className,
      disabled && styles.disabled,
    )}>
    {children}
  </div>
);