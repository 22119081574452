import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic: imageSharp(fluid: {originalName: {eq: "Platform - PlugAndPlay.jpg"}}) {
          fluid(maxWidth: 610, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic }) => (
      <SectionWrapper>
        <Grid container justifyContent='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.imageWrapper}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              className={styles.imageContainer}
              alt={i18n('Plug & play hardware and software')}
              fluid={pic.fluid}/>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
            className={styles.textContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Plug & play hardware and software')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('With more than 1+ billion older vehicles driving on the roads and the diversity of use cases, the need for additional hardware is present.')}
              <br />
              <br />
              {i18n('We have made the process of establishing the desired link to a vehicle truly plug & play.  This includes both the required hardware and software to get connected in the fastest way and the supporting processes to enhance the data quality.')}
            </Typography>
          </Grid>
          
        </Grid>
      </SectionWrapper>
    )}/>
);