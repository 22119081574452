import React from "react";
import Content from '../views/Platform';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Connected car platform enabling services at a scale",
    lang: 'en',
    description: "See connected car platform in action. OBI+ helps businesses, regardless of size, adopting connected car technology & services at scale.",
    meta: [{
      name: 'keywords',
      content: 'Connected car platform',
    }]
  }}/>
);