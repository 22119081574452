import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import sitemap from '../../data/sitemap';
import Overview from './Overview';
import Flowchart from './Flowchart';
import BeConnected from './BeConnected';
import SaasTools from './SaasTools';
import CallToAction from '../CallToAction';
import LeverageLinks from './LeverageLinks';
import DirectLink from './DirectLink';
import PlugAndPlay from './PlugAndPlay';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.platform}
    url='_url:platform'>
    <SEO {...seo} location={location}/>
    <Overview/>
    <Flowchart/>
    <LeverageLinks/>
    <DirectLink/>
    <PlugAndPlay/>
    <BeConnected/>
    <SaasTools/>
    <CallToAction/>
  </Layout>
)