import React from 'react';
import clsx from 'clsx';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({ error }) => (
  <Typography variant='h5'
    align='center'
    className={clsx(
      styles.error,
      error ? '' : styles.errorHidden
    )}
    color='alarm'>
    {i18n('Whoops! Something went wrong. Please try again...')}
  </Typography>
)